import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Provider as StoreProvider, useSelector } from 'react-redux';
import flagsmith from 'flagsmith';
import { useFlagsmith, FlagsmithProvider } from 'flagsmith/react';
import { History } from 'history';
import { Store } from 'redux';
import { env } from '@hum/common';
import { Action } from './actions';
import { CompanyType } from '@hum/types';
import { AppState } from './state';
import { __do_not_use_me_unless_you_know_what_you_are_doing__store } from './store';
import { AnalayticsProvider } from './modules/analytics/Context';
import { ModalContextProvider } from '@hum/design-system';
import { ContextMenuContainer } from '@hum/common/src/modules/context-menu';
import { DebugTools } from './components/DebugTools';
import { Confirmations } from '@hum/common/src/modules/confirm';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Router } from 'react-router';
import { ConfettiContainer } from './modules/confetti';
import { ToastProvider } from '@hum/common/src/modules/toast';
import { API, APIContext } from './backend';
import { datadogRum } from '@datadog/browser-rum';

export type ProviderProps = {
  // TODO: need to delete this since components shouldn't have access to the API
  api: API;
  store: Store<AppState, Action>;
  history: History;
  children: any;
};

const Inner = ({ children }) => {
  const state = useSelector((state: AppState) => state);
  const smithy = useFlagsmith();

  useEffect(() => {
    if (state.session.loaded && state.session.data) {
      const { data } = state.session;
      const id = String(data.id) || uuidv4();
      smithy.identify(data.email, {
        id,
        email: data.email ?? '',
        isAdmin: data.roles.includes(CompanyType.Admin),
        firstName: data.firstName ?? '',
        lastName: data.lastName ?? '',
        subdomain: data.subdomain ?? '',
        company: data.company?.name ?? 'Unknown Company',
      });
    }
  }, [state.session.loaded, state.session.data]);
  return (
    <AnalayticsProvider>
      <ModalContextProvider>
        <ContextMenuContainer>
          <DebugTools />
          <Confirmations />
          <ErrorBoundary />
          <ToastProvider />
          {children}
        </ContextMenuContainer>
      </ModalContextProvider>
      <ConfettiContainer />
    </AnalayticsProvider>
  );
};

export const Provider = ({ api, store, history, children }: ProviderProps) => {
  const flagsmithOptions = {
    environmentID: env.FLAGSMITH_CLIENT_SIDE_ENVIRONMENT_KEY || '',
    api: env.FLAGSMITH_API_URL || '',
    datadogRum: {
      client: datadogRum,
      trackTraits: true,
    },
  };

  return (
    <StoreProvider store={store}>
      <FlagsmithProvider options={flagsmithOptions} flagsmith={flagsmith}>
        <APIContext.Provider value={api}>
          <Router history={history}>
            <Inner>{children}</Inner>
          </Router>
        </APIContext.Provider>
      </FlagsmithProvider>
    </StoreProvider>
  );
};
