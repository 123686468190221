import { Formula, Operation } from './core';

export const REVENUES: Formula = /^revenues\./;
export const ASSETS: Formula = /^(current_assets|noncurrent_assets)\./;
export const EXPENSES: Formula = /^expenses\./;
export const INVESTMENTS: Formula = /^investments\./;
export const OTHER_INCOME_EXP: Formula = /^Other_income\/expense_net\./;
export const NET_INCOME_BY_COMPANY: Formula = /^reconciled.net_income/;
export const LIABILITIES: Formula = /^(current_liabilities|noncurrent_liabilities)\./;
export const EQUITY: Formula = /^(equity)/;

export const NET_INCOME: Formula = [
  REVENUES,
  Operation.Subtract,
  EXPENSES,
  Operation.Subtract,
  INVESTMENTS,
  Operation.Add,
  /\.Other_income$/,
  Operation.Add,
  /.Other_expense_negative$/,
  Operation.Subtract,
  [
    /\.Other_income_negative$/,
    Operation.Add,
    /\.Other_expense$/,
    Operation.Add,
    /financing\.debt_services\.fees/,
    Operation.Add,
    /financing\.debt_services\.interest/,
  ],
];

export const NET_INCOME_DIFFERENCE: Formula = [
  NET_INCOME_BY_COMPANY,
  Operation.Subtract,
  NET_INCOME,
];

export const BALANCE: Formula = [
  ASSETS,
  Operation.Subtract,
  [LIABILITIES, Operation.Add, EQUITY],
];
