import {
  DataLabel,
  DataRow,
  cellHasValue,
} from '@hum/labeling-ui/src/ducks/state';
import memoize from 'fast-memoize';
import React from 'react';
export const _updateDataColumnRefsPosition = (
  id: string,
  isGettingExpanded: boolean,
  dataColumnRefs: React.RefObject<HTMLElement[]>
) => {
  let dataColumnRef = document.getElementById(id);
  // if I am about to expand a group
  if (isGettingExpanded) {
    for (let possibleElementMatch of dataColumnRefs.current!) {
      // I just want to find the first el that is not the group I want to expand and
      // I want to match their position to the one of the group being expanded
      if (possibleElementMatch !== dataColumnRef) {
        dataColumnRef!.scrollLeft = possibleElementMatch.scrollLeft;
        break;
      }
    }
  } else {
    // I want to sync scrolling
    dataColumnRefs.current!.forEach((elementToMove: HTMLElement) => {
      elementToMove.scrollLeft = dataColumnRef!.scrollLeft;
    });
  }
};

export const _cancelScrollListeners = (
  dataColumnRefs: React.RefObject<HTMLElement[]>,
  id?: string
) => {
  dataColumnRefs.current!.forEach((dataColumnRef) => {
    if ((id && dataColumnRef.id !== id) || !id) {
      dataColumnRef.onscroll = null;
    }
  });
};

export const getMappedRows = memoize(
  (
    groupId: string,
    emptyRowsHidden: boolean,
    dataLabels: Record<string, DataLabel>,
    allDataRows: DataRow[]
  ) => {
    let filteredDataRows: DataRow[] = [];
    if (groupId && !['unmapped', 'date'].includes(groupId)) {
      filteredDataRows = allDataRows.filter((dataRow) => {
        const rowId = dataRow.id!;
        const dataLabel = dataLabels[rowId];
        return dataLabel && dataLabel.labels.includes(groupId);
      });
    } else {
      filteredDataRows = allDataRows.filter((dataRow) => {
        const rowId = dataRow.id!;
        const dataLabel = dataLabels[rowId];
        return !(dataLabel && dataLabel.labels.length > 0);
      });
    }

    if (emptyRowsHidden) {
      filteredDataRows = filteredDataRows.filter((dataRow) => {
        return dataRow.columns.some(cellHasValue);
      });
    }

    return filteredDataRows;
  }
);
