// Based on: https://docs.google.com/spreadsheets/d/1Rm2RkjgkHHPaUMAblxCYGOgv3h4gc5VpQOcFr1UBoWo/edit#gid=1227564387&range=A5

import { DataLabelingTab, LabelingGroup } from './ui';
import { EMPTY_ARRAY } from '@hum/common/src/ducks/state';
import memoize from 'fast-memoize';

export type TaxonomyItem = {
  name: string;
  colorCode?: string;
  children?: TaxonomyItem[];
  behavior?: TaxonomyBehavior;
  statementType?: DataLabelingTab | DataLabelingTab[];
};

export enum TaxonomyBehavior {
  RECONCILE_NET_INCOME = 'RECONCILE_NET_INCOME',
}

// temporary until there's an API for this
const TAXONOMY: TaxonomyItem[] = [
  {
    name: 'revenues',
    statementType: DataLabelingTab.IncomeStatements,
    colorCode: '#08BDBA',
    children: [
      { name: 'cohort' },
      { name: 'non_cohort' },
      { name: 'non_cohort_separate_cogs' },
      { name: 'non_cohort_no_cogs' },
      { name: 'non_recurring' },
    ],
  },
  {
    name: 'expenses',
    statementType: DataLabelingTab.IncomeStatements,
    colorCode: '#DFBF03',
    children: [
      { name: 'gross_cashflow_related' },
      { name: 'revenue_related.non_inventory_cogs' },
      { name: 'revenue_related.non_inventory_cogs_non_cohort' },
      { name: 'revenue_related.inventory_recoupment' },
      { name: 'revenue_related.inventory_recoupment_non_cohort' },
      { name: 'revenue_related.financial_principal_recoupment' },
      { name: 'opex_related' },
      { name: 'r_and_d_related' },
      { name: 'non_recurring' },
      { name: 'D_and_A' },
      { name: 'non_cash_expenses' },
    ],
  },
  {
    name: 'investments',
    statementType: DataLabelingTab.IncomeStatements,
    colorCode: '#4A66F4',
    children: [
      { name: 'sales_and_marketing' },
      { name: 'inventory' },
      { name: 'loan' },
      { name: 'equity' },
      { name: 'other' },
    ],
  },
  {
    name: 'bookings',
    statementType: DataLabelingTab.IncomeStatements,
    colorCode: '#AEC3FC',
    children: [{ name: 'cohort' }, { name: 'non_cohort' }],
  },
  {
    name: 'financing',
    statementType: DataLabelingTab.IncomeStatements,
    colorCode: '#DA348F',
    children: [
      { name: 'debt_raised' },
      {
        name: 'debt_services',
        children: [
          { name: 'principal' },
          { name: 'interest' },
          { name: 'fees' },
        ],
      },
      { name: 'equity_raised' },
      {
        name: 'equity_services',
        children: [{ name: 'principal' }, { name: 'income' }],
      },
    ],
  },
  {
    name: 'gross_cashflows',
    statementType: DataLabelingTab.IncomeStatements,
    colorCode: '#28A138',
    children: [
      { name: 'cohort' },
      { name: 'non_cohort' },
      { name: 'non_recurring' },
    ],
  },
  {
    name: 'Other_income/expense_net',
    statementType: DataLabelingTab.IncomeStatements,
    colorCode: '#007D79',
    children: [
      { name: 'Other_income' },
      { name: 'Other_income_negative' },
      { name: 'Other_expense' },
      { name: 'Other_expense_negative' },
    ],
  },
  {
    name: 'reconciled',
    statementType: DataLabelingTab.IncomeStatements,
    colorCode: '#E8ECF2',
    children: [
      { name: 'net_income', behavior: TaxonomyBehavior.RECONCILE_NET_INCOME },
    ],
  },
  {
    name: 'current_assets',
    statementType: DataLabelingTab.BalanceSheets,
    children: [
      { name: 'cash_and_cash_equivalents' },
      { name: 'net_receivables' },
      { name: 'net_inventory' },
      { name: 'prepaid_expenses' },
      { name: 'deferred_expenses' },
      { name: 'net_contracts_with_customers' },
      { name: 'derivatives_and_hedges' },
      { name: 'other_current_assets' },
    ],
  },
  {
    name: 'noncurrent_assets',
    statementType: DataLabelingTab.BalanceSheets,
    children: [
      { name: 'inventory' },
      { name: 'property_plant_and_equipment' },
      { name: 'longterm_investments_and_receivables' },
      { name: 'net_intangible_assets_and_goodwill' },
      { name: 'prepaid_expenses' },
      { name: 'deferred_expenses' },
      { name: 'net_contracts_with_customers' },
      { name: 'derivatives_and_hedges' },
      { name: 'other_assets' },
      { name: 'restricted_cash_and_cash_equivalents' },
    ],
  },
  {
    name: 'current_liabilities',
    statementType: DataLabelingTab.BalanceSheets,
    children: [
      { name: 'accounts_payable' },
      { name: 'debt' },
      { name: 'derivatives_and_hedges' },
      { name: 'deferred_revenue' },
      { name: 'provision_and_accruals' },
      { name: 'provision_for_losses_on_contracts' },
      { name: 'provision_for_refunds' },
      { name: 'other_liabilities' },
    ],
  },
  {
    name: 'noncurrent_liabilities',
    statementType: DataLabelingTab.BalanceSheets,
    children: [
      { name: 'accounts_payable' },
      { name: 'longterm_debt' },
      { name: 'derivatives_hedges' },
      { name: 'deferred_revenue' },
      { name: 'provision_and_accruals' },
      { name: 'provision_for_losses_on_contracts' },
      { name: 'provision_for_refunds' },
      { name: 'other_liabilities' },
    ],
  },
  {
    name: 'equity',
    statementType: DataLabelingTab.BalanceSheets,
    children: [{ name: 'paid_in_capital' }, { name: 'retained_earnings' }],
  },
  {
    name: 'excluded',
    statementType: [
      DataLabelingTab.IncomeStatements,
      DataLabelingTab.BalanceSheets,
    ],
    colorCode: '#FF6B6B',
    children: [
      { name: 'sum' },
      { name: 'difference' },
      { name: 'contra_account' },
      { name: 'blank' },
    ],
  },
];

const taxonomyItemsToLabelGroups = (
  items: TaxonomyItem[],
  path: string[] = [],
  statementType?: DataLabelingTab | DataLabelingTab[],
  colorCode?: string
): LabelingGroup[] =>
  items.map((item) => {
    const selfPath = [...path, item.name];

    const statementType2 = item.statementType || statementType!;

    return {
      id: selfPath.join('.'),
      path: selfPath.join('.'),
      statementTypes: Array.isArray(statementType2)
        ? statementType2
        : [statementType2],
      colorCode: item.colorCode || colorCode || 'rgba(255, 255, 255, 0.8)',

      // DEPRECATED
      title: item.name,
      title2: selfPath.join(', '),
      behavior: item.behavior,
      children: taxonomyItemsToLabelGroups(
        item.children || EMPTY_ARRAY,
        selfPath,
        item.statementType || statementType!,
        item.colorCode || colorCode
      ),
    };
  });

export const TAXONOMY_LABEL_GROUPS = taxonomyItemsToLabelGroups(TAXONOMY);

export const getTaxonomyLabelGroupsByStatementType = memoize(
  (groups: LabelingGroup[], statementType: DataLabelingTab) =>
    groups.filter((group) => group.statementTypes.includes(statementType))
);
