import { Result } from '@hum/common/src/ducks/state';
import { createSelector } from 'reselect';
import memoize from 'fast-memoize';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import max from 'lodash/max';
import crc32 from 'buffer-crc32';
import { getHistoryState, HistoryState, domHistory } from '@hum/common';
import { TAXONOMY_LABEL_GROUPS, TaxonomyBehavior } from './taxonomy';
export { TaxonomyBehavior };
import {
  DataRow,
  DataLabelingTab,
  DataSource,
  LabelingGroup,
  CellAlignment,
  DataAssetRow,
} from './ui';
import { ConfirmationsAppState } from '@hum/common/src/modules/confirm';
import { computeRowsWithFormula } from '../../components/App/LabelGroups/compute/core';
import {
  BALANCE,
  NET_INCOME_DIFFERENCE,
} from '../../components/App/LabelGroups/compute/formulas';

export * from './ui';

export enum DataSorting {
  ByLabel = 'ByLabel',
  OriginalOrder = 'OriginalOrder',
}

export type DataLabel = {
  dataSourceId: string;
  dataType: string;
  dataLabelKey: string;
  fieldName: string;
  accountId?: string;
  rowId: string;
  ignoreField: boolean;
  /**
   * Path to target tables
   */
  labels: string[];
  splits: number[];
};

/*

[
  [0, 1, 2, 3, 4], // row
  [0, 1, 2, 3, 4],
  [0, 1, 2, 3, 4]
]
*/

export type LabelingGroupData = Array<any>;

export const isLabelingGroupMappable = (group: LabelingGroup) => {
  return group.children.length === 0;
};

export const LABEL_MAPPER_TAB_ID = 'label-mapper-tab';
export const DATA_SORTING_QUERY_NAME = 'sorting';

export enum AutoLabelingStatus {
  NotStarted = 'NOT_STARTED',
  WaitingOnTask = 'WAITING_ON_TASK',
  WaitingOnData = 'WAITING_ON_DATA',
}

export type DataLabelingState = {
  autoLabelingStatus: AutoLabelingStatus;
  history: HistoryState;
  cellAlignment: CellAlignment;
  emptyRowsHidden: boolean;
  reconciliationTableVisible: boolean;
  labelingCompleted: boolean;
  canLabelingBeCompleted: boolean;
  dataTableScrollLeft?: { value: number; origin: number };

  // data type (the tab that the user is on) -> sourceId -> true / false
  // ignoredSourceIds: Record<string, Record<string, boolean>>;

  savingState?: Result<any>;

  resourcesLoaded: boolean;
  dataSources: DataSource[];

  pinnedColumns: Record<string, string>;

  // Todo - rename to label maps
  dataLabels: Record<string, DataLabel>;
  dataRows: DataRow[];

  // Source ID that points its related data types
  // Each data type points to a boolean, stating whether or not the source should be ignored.
  ignoredSources: Record<string, Record<string, boolean>>;

  selectedLabelRowIds: string[];

  selectedColumnIndex?: number;

  selectedDataTypeTab: DataLabelingTab;

  // mappable groups
  groups: Result<LabelingGroup[]>;
  errors: string[];
} & ConfirmationsAppState;

export const ID_SEPARATOR = `:::`;

export const getDataRowId = (
  dataSourceId: string,
  dataType: string,
  fieldName: string,
  dataLabelKey: string
) =>
  `${dataSourceId}${ID_SEPARATOR}${dataType}${ID_SEPARATOR}${fieldName}${ID_SEPARATOR}${dataLabelKey}`;

export const getDataAssetRowId = (dataAssetId: number, rowLabel: string) =>
  `${dataAssetId}${ID_SEPARATOR}${rowLabel}`;

export const selectDataSorting = (state: DataLabelingState) =>
  state.history.query[DATA_SORTING_QUERY_NAME] || DataSorting.OriginalOrder;

export const selectPinnedColumns = (state: DataLabelingState) =>
  state.pinnedColumns;

const selectAllDataSources = (state: DataLabelingState) => state.dataSources;
const selectHistory = (state: DataLabelingState) => state.history;

export const selectCurrentStatementType = createSelector(
  selectHistory,
  (history) => {
    const currentTab = history.query[LABEL_MAPPER_TAB_ID];

    if (currentTab) {
      if (isNaN(Number(currentTab))) {
        return currentTab;
      } else {
        const tabValues = Object.values(DataLabelingTab);
        return tabValues[Number(currentTab)];
      }
    }

    return DataLabelingTab.IncomeStatements;
  }
);

export const selectDataSources = createSelector(
  selectAllDataSources,
  selectCurrentStatementType,
  (dataSources, currentStatementType) => {
    return dataSources.filter((source) =>
      source.dataTypes.includes(currentStatementType)
    );
  }
);

export const selectDataSourceOptions = createSelector(
  selectDataSources,
  (dataSources) =>
    dataSources.map((source) => ({
      label: source.sourceName,
      value: source.id,
    }))
);

export const selectDataSourceIds = createSelector(
  selectDataSources,
  (dataSources) => dataSources.map((source) => source.id)
);

export const selectSelectedDataSources = createSelector(
  selectDataSources,
  (dataSources) =>
    dataSources.reduce(
      (sources: DataSource[], dataSource: DataSource) =>
        dataSource.isEnabled ? [...sources, dataSource] : sources,
      []
    )
);

export const selectSelectedDataSourceIds = createSelector(
  selectSelectedDataSources,
  (selectedDataSources) => selectedDataSources.map((source) => source.id)
);

export const selectSelectedDataSourceOptions = createSelector(
  selectSelectedDataSources,
  (selectedDataSources) =>
    selectedDataSources.map((source) => ({
      label: source.sourceName,
      value: source.id,
    }))
);

export const getRowIdLabel = (id: string) => {
  const [, rowLabel] = id.split(ID_SEPARATOR);
  return rowLabel;
};

export const selectAllDataLabels = (state: DataLabelingState) =>
  state.dataLabels;

export const selectContainsDataLabelMap = createSelector(
  selectAllDataLabels,
  (dataLabels) => {
    for (const rowId in dataLabels) {
      if (dataLabels[rowId].labels.length > 0) {
        return true;
      }
    }
    return false;
  }
);

const PARTIAL_LABELING_STATE = {
  autoLabelingStatus: AutoLabelingStatus.NotStarted,
  toasts: [],
  cellAlignment: CellAlignment.LEFT,
  emptyRowsHidden: true,
  labelingCompleted: false,
  reconciliationTableVisible: true,
  canLabelingBeCompleted: true,
  resourcesLoaded: false,
  dataSources: [],
  dataLabels: {},
  // ignoredSourceIds: {},
  dataRows: [],
  ignoredSources: {},
  selectedLabelRowIds: [],
  confirmations: [],
  pinnedColumns: {},
  selectedDataTypeTab: DataLabelingTab.IncomeStatements,
  groups: {
    loaded: true,
    data: TAXONOMY_LABEL_GROUPS,
  },
  errors: [],
};

export const createInitialState = (): DataLabelingState => {
  const history = getHistoryState(domHistory);

  return {
    ...PARTIAL_LABELING_STATE,
    history,
    selectedDataTypeTab: selectCurrentStatementType({
      ...PARTIAL_LABELING_STATE,
      history,
    }),
  };
};

export const cellHasValue = (cell: string | number | undefined) =>
  cell && cell !== '' && cell !== '0.00';

export const getDataLabelingGroupAccordionIdMap = (
  labelGroups: LabelingGroup[],
  map: Record<string, string> = {}
) => {
  for (const group of labelGroups) {
    map[group.id] = getLabelingAccordionId(group.id);
    if (!isLabelingGroupMappable(group)) {
      getDataLabelingGroupAccordionIdMap(group.children, map);
    }
  }

  map['unmapped'] = 'unmapped';

  return map;
};

export const getLabelingAccordionId = memoize((id: string) => {
  return crc32(id).toString('base64').replace(/\W/g, '');
});

export const createDataLabel = (
  rowId: string,
  dataSourceId: string,
  dataLabelKey: string,
  dataType: string,
  fieldName: string,
  accountId: string | undefined
): DataLabel => ({
  rowId,
  dataSourceId,
  accountId,
  dataLabelKey,
  dataType,
  fieldName,
  ignoreField: false,
  labels: [],
  splits: [],
});

/**
 */

export const allLabelingGroupsExpanded = (
  labelGroups: LabelingGroup[],
  locationQuery: Record<string, any>
) => {
  const accordionGroupMap = getDataLabelingGroupAccordionIdMap(labelGroups);
  const allAccordionIds = Object.values(accordionGroupMap).sort();
  const expandedAccordionIds = (locationQuery.expand || '')
    .split(',')
    .sort()
    .filter(Boolean);
  return isEqual(expandedAccordionIds, allAccordionIds);
};

/**
 */

export const toggleDataLabelingGroupExpansion = (
  labelGroups: LabelingGroup[],
  locationQuery: Record<string, any>
): Record<string, any> => {
  const accordionGroupMap = getDataLabelingGroupAccordionIdMap(labelGroups);
  const allAccordionIds = Object.values(accordionGroupMap).sort();

  if (allLabelingGroupsExpanded(labelGroups, locationQuery)) {
    // remove expansion
    return omit(locationQuery, ['expand']);
  } else {
    // otherwise, expand all
    return {
      ...locationQuery,
      expand: allAccordionIds.join(','),
    };
  }
};

export type LabelGroupInfo = {
  ancestors: LabelingGroup[];
  item: LabelingGroup;
};

const addLabelGroupMap = (
  labelGroup: LabelingGroup,
  map: Record<string, LabelGroupInfo>,
  ancestors: LabelingGroup[]
) => {
  map[labelGroup.id] = {
    item: labelGroup,
    ancestors,
  };

  if (!isLabelingGroupMappable(labelGroup)) {
    const childParents = [labelGroup, ...ancestors];
    for (const child of labelGroup.children) {
      addLabelGroupMap(child, map, childParents);
    }
  }
};

export const getLabelGroupMap = memoize((labelingGroups: LabelingGroup[]) => {
  const map: Record<string, LabelGroupInfo> = {};

  for (const group of labelingGroups) {
    addLabelGroupMap(group, map, []);
  }

  return map;
});

/**
 * Finds a nested label group based on the `test` parameter. For example:
 * findLabelGroup(group => group.id == "reconcile")([{ id: "unmapped" }, { id: "reconcile" }]) //  { id: "reconcile" }
 */

export const findLabelGroup = (test: (group: LabelingGroup) => boolean) =>
  memoize((allGroups: LabelingGroup[]): LabelingGroup | null => {
    let found: any = null;
    traverseGroups(allGroups, (group: LabelingGroup) => {
      if (test(group)) {
        found = group;
        return false;
      }
    });

    return found;
  });

/**
 * Finds multiple groups based on the `filter` parameter. Similar to findLabelGroup
 */

export const filterLabelGroups = (filter: (group: LabelingGroup) => boolean) =>
  memoize((allGroups: LabelingGroup[]): LabelingGroup[] => {
    const found: LabelingGroup[] = [];
    traverseGroups(allGroups, (group: LabelingGroup) => {
      if (filter(group)) {
        found.push(group);
      }
    });

    return found;
  });

/**
 * Traverses a labeling group tree using the `walk` function. Returning _false_ in
 * the walk function stops the tree traversal (used in findLabelGroup)
 */

const traverseGroups = (
  allGroups: LabelingGroup[],
  walk: (group: LabelingGroup) => void | boolean
) => {
  const find2 = (group: LabelingGroup): boolean => {
    if (walk(group) === false) return false;
    for (const child of group.children) {
      if (find2(child) === false) {
        return false;
      }
    }
    return true;
  };

  for (const group of allGroups) {
    if (find2(group) === false) {
      return;
    }
  }
};

export const getDataAssetRowsResult = (
  record: Record<string, Result<DataAssetRow>>,
  dataAssetId: number
) => record[dataAssetId];

export const selectCompanyIdFromQuery = createSelector(
  selectHistory,
  (history) => history.query.company_id
);

export const selectCompanyIdFromParams = createSelector(
  selectHistory,
  (history) => history.params.companyId
);

export const selectScrollLeft = (state: DataLabelingState) =>
  state.dataTableScrollLeft;

const selectAllDataRows = (state: DataLabelingState) => state.dataRows;

export const selectEnabledDataRows = createSelector(
  selectAllDataSources,
  selectAllDataRows,
  selectCurrentStatementType,
  (dataSources, dataRows, currentStatementType) => {
    const enabledDataSourceIds = dataSources.reduce(
      (sourceIds: string[], source: DataSource) =>
        source.isEnabled ? [...sourceIds, source.id] : sourceIds,
      []
    );

    return dataRows.filter(
      (row, index) =>
        index > 0 &&
        row.dataType == currentStatementType &&
        enabledDataSourceIds.includes(row.dataSourceId!)
    );
  }
);

export const selectRowLabelGroupMap = createSelector(
  selectAllDataLabels,
  (dataLabels) => {
    const targetLabelGroups: Record<
      string,
      { group: LabelingGroup; map: DataLabel }
    > = {};

    for (const rowId in dataLabels) {
      const labelMap = dataLabels[rowId];
      if (labelMap.labels.length) {
        targetLabelGroups[rowId] = {
          group: getLabelGroupById(labelMap.labels[0], TAXONOMY_LABEL_GROUPS)!,
          map: labelMap,
        };
      }
    }

    return targetLabelGroups;
  }
);

const getLabelGroupById = memoize(
  (groupId: string, groups: LabelingGroup[]): LabelingGroup | null => {
    let found: LabelingGroup | null = null;
    traverseGroups(groups, (descendent) => {
      if (descendent.id === groupId) {
        found = descendent;
        return false;
      }
    });

    return found;
  }
);

export const selectPeriods = createSelector(selectAllDataRows, (dataRows) => {
  if (!dataRows.length) {
    return [];
  }

  // ["Field name", "2022", "2023"];
  return dataRows.slice(0, 1)[0].columns;
});

export const selectSelectedRowIds = (state: DataLabelingState) =>
  state.selectedLabelRowIds;

export const selectTargetLabels = createSelector(
  selectSelectedRowIds,
  selectAllDataLabels,
  (selectedLabelRowIds, dataLabels) => {
    const labels: Record<string, number> = {};
    for (const selectedRowId of selectedLabelRowIds) {
      const labelMap = dataLabels[selectedRowId];
      if (labelMap) {
        for (const targetLabel of labelMap.labels) {
          if (!labels[targetLabel]) {
            labels[targetLabel] = 0;
          }
          labels[targetLabel]++;
        }
      }
    }

    return Object.keys(labels).filter(
      (key) => labels[key] === selectedLabelRowIds.length
    );
  }
);

export const selectSelectedColumnIndex = (state: DataLabelingState) =>
  state.selectedColumnIndex;

export const isDataLabelEnabled = (
  dataLabel: DataLabel,
  state: DataLabelingState
) => {
  const isEnabled = isDataSourceEnabled(dataLabel.dataSourceId, state);
  return isEnabled != null ? isEnabled : !dataLabel.ignoreField;
};

export const isDataSourceEnabled = (
  dataSourceId: string,
  state: DataLabelingState
) =>
  state.dataSources.find((source) => source.id === dataSourceId)?.isEnabled ===
  true;

export const areLabelingRequirementsFulfilled = memoize(
  (state: DataLabelingState) => {
    return !isLabelingNotFinished(state);
  }
);

export const selectAutoLabelingStatus = (state: DataLabelingState) =>
  state.autoLabelingStatus;

export const isLabelingNotFinished = memoize(
  ({
    dataSources,
    autoLabelingStatus,
    ignoredSources,
    dataRows: allDataRows,
    dataLabels,
    emptyRowsHidden,
  }: DataLabelingState) => {
    if (autoLabelingStatus !== AutoLabelingStatus.NotStarted) {
      return true;
    }

    for (const dataSource of dataSources) {
      for (const dataType of dataSource.dataTypes) {
        if (ignoredSources[dataType][dataSource.id] === false) {
          const sourceDataRows = allDataRows.filter(
            (dataRow) =>
              dataRow.dataSourceId === dataSource.id &&
              dataRow.dataType === dataType
          );
          const dataLabelsEntries = Object.entries(dataLabels);
          const relevantDataLabelsEntries = dataLabelsEntries.filter(
            ([_, dataLabel]) =>
              dataLabel.dataSourceId === dataSource.id &&
              dataLabel.dataType === dataType
          );
          const relevantDataLabels = Object.fromEntries(
            relevantDataLabelsEntries
          );
          const unmappedRows = getUnMappedRows(
            emptyRowsHidden,
            relevantDataLabels,
            sourceDataRows
          );
          if (unmappedRows.length > 0) {
            return true;
          }
        }
      }
    }
    return false;
  }
);

export const RECONCILED_DIFF_THRESHOLD = 1000; // $1,000

export const selectAreReconciledRowsInRange = createSelector(
  selectEnabledDataRows,
  selectAllDataLabels,
  (dataRows, dataLabels) => {
    if (!dataRows.length) {
      return true;
    }

    const largestDiff = (max([
      ...computeRowsWithFormula(
        BALANCE,
        TAXONOMY_LABEL_GROUPS,
        dataLabels,
        dataRows,
        dataRows[0].columns.length
      ).map(Math.abs),
      ...computeRowsWithFormula(
        NET_INCOME_DIFFERENCE,
        TAXONOMY_LABEL_GROUPS,
        dataLabels,
        dataRows,
        dataRows[0].columns.length
      ).map(Math.abs),
    ]) || 0) as number;

    return largestDiff <= RECONCILED_DIFF_THRESHOLD;
  }
);

export const getUnMappedRows = memoize(
  (
    emptyRowsHidden: boolean,
    dataLabels: Record<string, DataLabel>,
    allDataRows: DataRow[]
  ) => {
    let filteredDataRows: DataRow[] = [];
    filteredDataRows = allDataRows.filter((dataRow) => {
      const rowId = dataRow.id!;
      const dataLabel = dataLabels[rowId];
      return !dataLabel || dataLabel.labels.length === 0;
    });

    if (emptyRowsHidden) {
      filteredDataRows = filteredDataRows.filter((dataRow) => {
        return dataRow.columns.some(cellHasValue);
      });
    }

    return filteredDataRows;
  }
);
