import {
  DataColumn,
  DataLabel,
  DataRow,
  filterLabelGroups,
  LabelingGroup,
} from '@hum/labeling-ui/src/ducks/state';
import memoize from 'fast-memoize';
import { getMappedRows } from '../utils';

export enum Operation {
  Subtract,
  Add,
}

export type Pattern = RegExp;
type Arithmetic = Array<Pattern | Operation | Arithmetic>;
export type Formula = Pattern | Arithmetic;

export const computeRowsWithFormula = (
  formula: Formula,
  allGroups: LabelingGroup[],
  dataLabels: Record<string, DataLabel>,
  allRows: DataRow[],
  columnCount: number
) => {
  if (isPattern(formula)) {
    const filtered = filterLabelGroups((group) => formula.test(group.id))(
      allGroups
    );
    return squashGroupsIntoRow(dataLabels, allRows, columnCount)(filtered);
  } else {
    // start with the first 1
    let sum = computeRowsWithFormula(
      formula[0] as Formula,
      allGroups,
      dataLabels,
      allRows,
      columnCount
    );

    for (let i = 1; i < formula.length; i += 2) {
      const op = formula[i];
      const right = computeRowsWithFormula(
        formula[i + 1] as Formula,
        allGroups,
        dataLabels,
        allRows,
        columnCount
      );
      if (op === Operation.Add) {
        sum = addRows([sum, right], columnCount);
      } else if (op === Operation.Subtract) {
        sum = subtractRows([sum, right], columnCount);
      }
    }

    return sum;
  }
};

const isPattern = (formula: Formula): formula is Pattern => {
  return formula instanceof RegExp;
};

const squashGroupsIntoRow = (
  dataLabels: Record<string, DataLabel>,
  allDataRows: DataRow[],
  length: number
) => (groups: any) =>
  addRows(combineGroupRows(groups, dataLabels, allDataRows), length);

const combineGroupRows = (
  groups: LabelingGroup[],
  dataLabels: Record<string, DataLabel>,
  allDataRows: DataRow[]
) => {
  return groups.reduce((table: DataColumn[][], group: LabelingGroup) => {
    return [...table, ...getMappedSplitRows(group, dataLabels, allDataRows)];
  }, []);
};

const getMappedSplitRows = (
  group: LabelingGroup,
  dataLabels: Record<string, DataLabel>,
  allDataRows: DataRow[]
) => {
  const mappedRows = getMappedRows(group.id, true, dataLabels, allDataRows);
  return addRowSplits(mappedRows, group.id, dataLabels);
};

const addRowSplits = (
  dataRows: DataRow[],
  groupId: string,
  dataLabels: Record<string, DataLabel>
) => {
  return dataRows.map((dataRow) => {
    const split = getRowSplit(dataRow, groupId, dataLabels);
    return dataRow.columns.map((column) => {
      // Round to nearest decimal place so that user doesn't see an invalid dollar amount
      return Number((Number(column || 0) * split).toFixed(2));
    });
  });
};

const getRowSplit = memoize(
  (
    dataRow: DataRow,
    groupId: string,
    dataLabels: Record<string, DataLabel>
  ) => {
    const rowId = dataRow.id!;
    const dataLabel = dataLabels[rowId];
    return dataLabel!.splits[dataLabel!.labels.indexOf(groupId)];
  }
);

const reduceColumns = (reduce: (total: number, value: number) => number) => (
  rows: DataColumn[][],
  length: number
): DataColumn[] => {
  // if no rows, then zero it out so that we can continue to use the computed rows against other
  // functions.
  if (!rows.length) {
    return Array.from({ length }).map(() => 0);
  }
  if (rows.length === 1) {
    return rows[0];
  }

  const start = rows[0];
  const extra = rows.slice(1);

  return start.map((value, columnIndex) => {
    return extra.reduce((columnSum, row) => {
      const column = row[columnIndex];
      const columnNum = Number(column) || 0;

      // Round to nearest decimal place so that user doesn't see an invalid dollar amount
      return Number(Number(reduce(columnSum, columnNum)).toFixed(2));
    }, Number(value) || 0);
  });
};

const addRows = reduceColumns((current, column) => current + column);
const subtractRows = reduceColumns((current, column) => current - column);
