import { TaxonomyBehavior } from './taxonomy';
import memoize from 'fast-memoize';

/**
 * UI Specific state
 */

export enum DataLabelingTab {
  IncomeStatements = 'income_statement',
  BalanceSheets = 'balance_sheet',
  Transactions = 'transactions',
}

export const DATA_LABELING_TAB_DISPLAY_NAMES = {
  [DataLabelingTab.IncomeStatements]: 'Income Statements',
  [DataLabelingTab.BalanceSheets]: 'Balance Sheets',
  [DataLabelingTab.Transactions]: 'Transactions',
};

export type DataSource = {
  id: string;
  sourceName: string;

  /**
   * @deprecated. Need to take this state from current statement type
   */
  dataTypes: DataLabelingTab[];
  isEnabled: boolean;
};

export type DataColumn = string | number;

export type DataRow = {
  // maybe a date row
  id: string | null;
  dataSourceId?: string;
  dataSourceName?: string;
  dataLabelKey: string;
  accountId?: string;
  dataType?: string;
  fieldName: string;
  columns: DataColumn[];
};

/**
 */

export enum CellAlignment {
  LEFT,
  RIGHT,
}

export type LabelingGroup = {
  statementTypes: DataLabelingTab[];
  uncategorized?: boolean;
  colorCode?: string;
  title2: string;
  path?: string;
  id: string;

  /**
   * @deprecated
   */
  title: string;
  behavior?: TaxonomyBehavior;
  children: Array<LabelingGroup>;
};

export const flattenLabelingGroup = memoize((group: LabelingGroup) => {
  const descendents: LabelingGroup[] = [];
  const traverse = (descendent: LabelingGroup) => {
    descendents.push(descendent);
    for (const child of descendent.children) {
      traverse(child);
    }
  };
  group.children.map(traverse);
  return descendents;
});

export type DataAssetColumn = string | number;
export type DataAssetRow = Array<DataAssetColumn>;
export type DataAssetRows = Array<DataAssetRow>;
